/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'src/theme.scss';

@font-face {
   font-family: 'OpenSans';
   src: url('./assets/fonts/OpenSans-Regular.ttf') format('ttf');
}
/****** Modified Styles 18-Dec-2023*****/
/*.cdk-overlay-pane{
	width: auto !important;
}*/	

.modal-step-config .sub-title{
   margin: 0px 10px 0px;
}

.modal-step-config .mat-mdc-dialog-content{
   min-width: 1050px;
   min-height: 490px;
}
.mdc-dialog .modal-step-config .mat-mdc-dialog-content{
   padding: 7px 0px 0px 0px !important;
}

/* .modal-step-config .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
   height: 4px !important;
} */

.modal-step-config .table{
margin-bottom: 2px !important;
}
.sensor-name-value .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
   padding: 0 !important;
   min-height: auto !important;
}
.sensor-name-value .mat-mdc-option .mat-pseudo-checkbox-minimal {
   margin-left: 7px;
}
/* .sensor-name-value .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
   display: none;
} */
.config-modal-area label{
   margin-bottom: 0 !important;
}
.model-nothingbutton{
   width: 24px !important;
   height: 24px !important;
   margin: 0px !important;
   padding: 0px !important;
}
.model-nothingbutton .mat-mdc-button-touch-target{
   display: none !important;
}
.model-nothingbutton[disabled]{
   cursor: not-allowed !important;
}
.model-nothingbutton[disabled] .mat-icon.mat-primary, .model-nothingbutton[disabled] .mat-icon.mat-warn{
   cursor: not-allowed !important;
   --mat-icon-color: #8b8e95 !important;
}
.model-edit-style .mat-mdc-form-field{
   width: calc(100% - 60px);
}
.model-edit-style .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
   padding: 0 !important;
}
.model-name-block .mat-mdc-form-field-subscript-wrapper{
   display: none;
}
.mnot .mat-mdc-form-field{
   width: calc(100% - 60px);
   cursor: pointer;
}
.model-section-box span{
   width: calc(100% - 60px);
   cursor: pointer;
   padding: 5px 0px;
}
.model-section-box .model-selected span{
   width: calc(100% - 60px);
   cursor: default;
}
.model-selected{
   background-color: #BBDEFB;
   color: #212529;
   padding: 3px 10px;
}
.mnot{
   padding: 3px 10px;
}
.model-selected .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
   padding: 0 !important;
}
.model-selected .mat-mdc-form-field-infix {
   min-height: auto;
}
.model-edit-style .mat-mdc-form-field-infix{
   padding: 0 !important;
   min-height: auto !important;
}
.model-section-box{
   background: #fff;
   border: 1px solid #ddd;
   border-radius: 4px;
   padding: 15px;
   box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.gases{
   min-height: 145px;
    margin-bottom: 15px;
}

.tooltiplikeDiv{
   font-size: 14px;
   background-color: #000;
   width: max-content;
   padding: 6px;
   border-radius: 10px;
}

.visiblepassicon{
   cursor: pointer;
   position: absolute;
   right: 0px;
}
.rpaendownload{
   padding-left: 10px;
   position: absolute;
   padding-top: 3px;
   cursor: pointer;
}
.black-text{
   color: #000000;
}
.red-text{
   color: #fd0000;
}
.white-text{
   color: #fff;
}
.black-text:hover{
   color: #000000;
}
.white-text:hover{
   color: #fff;
}
.login-window .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
   border-color: #A7A7A7 !important;
   background-color: #fff !important;
}
.login-window .mdc-checkbox__background{
   width: 15px !important;
   height: 15px !important;
   border: 1px solid;
   border-radius: 1px;
}
.login-window .omniscent-app label.mdc-label{
   margin-left: 0 !important;
}
.login-window .email-box{
   margin-bottom: 20px !important;
}
.font-18, .font-18 label{
   font-size: 18px !important;
}
.login-window .mdc-line-ripple::before, .login-window .mdc-line-ripple::after{
   border: 0 !important;
}
.login-window .mat-mdc-card-outlined{
   background: none;
   border: 0;
   box-shadow: unset !important;
}
.login-window .mat-mdc-form-field-subscript-wrapper{
   top: 33px;
}
app-login .login-window .sign-form-area .sign-form-wrapper input{
   max-width: 249px;/*270px*/
   margin-left: 10px;
}
app-login .login-window button.mdc-button {
   margin-top: 17px;
   height: 49px;
   background: #39B54A !important;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 30px;
   font-size: 18px !important;
   font-weight: 300 !important;
}
.login-window .mat-mdc-text-field-wrapper{
   position: absolute;
   max-width: 280px;
}
.login-window .omni-link{
   margin-top: 23px;
}
.field-bg{
   background: #FFFFFF;
   border-radius: 30px;
   padding: 10px 20px 14px 20px;
}
app-login{
   position: relative;
}
app-login h1{
   font-style: normal;
   font-weight: 400;
   font-size: 22px;
   line-height: 36px;
   text-align: center;
   color: #FFFFFF;
   margin-bottom: 40px;
}
app-login h1 span{
   font-size: 50px;
}
app-login h3{
   font-style: normal;
   font-weight: 400;
   font-size: 19px;
   line-height: 19px;
   text-align: center;
   color: #FFBA01;
}
.login-welcome-msg .logo{
   max-width: 316px;
}
.login-window{
   max-width: 975px;
   width: 975px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  background: #fff;
}
.welcome-login{
   width: 58%;
   background: #2962D3;
   padding: 40px 0 51px 0;
}
.login-welcome-msg{
   width: 48%;
   background: #fff;
   text-align: center;
}
.omniscent-app .login-window label.mdc-label {
   margin-left: 0px !important;
}
.omniscent-app .login-window .mdc-checkbox .mdc-checkbox__background {
   top: 2px;
}
.welcome-caption .mat-mdc-card-title{
   font-style: normal;
   font-weight: 300 !important;
   font-size: 23px !important;
   line-height: 23px !important;
   text-align: center ;
   color: #636363 !important;
}
app-login * {
   letter-spacing: 0px !important;
}
app-login .mat-mdc-raised-button {
   --mdc-protected-button-container-height: 38px;
   margin-top: 4px;
}
.omniscent-app app-login .mat-mdc-card-title {
   margin-bottom: 0px !important;
}
app-login .form-group {
   margin-bottom: 4px !important;
}
app-login .mat-mdc-form-field-infix {
   min-height: 28px !important; /*42px*/
}
app-login .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
   padding-top: 0px !important; /*23px*/
   padding-bottom: 0px !important; /*4px*/
}
app-login .sign-form-area .sign-form-wrapper input {
   font-size: 18px !important;
   letter-spacing: 0;
}
app-login .mat-mdc-form-field-hint-wrapper, app-login .mat-mdc-form-field-error-wrapper {
   padding: 0 0px !important;
}
.status-title {
   line-height: 1.5;
}
app-admin mat-sidenav .child-nav .active .mdc-list-item__primary-text {
   color: #ffb74d !important;
}
app-admin mat-sidenav .child-nav .active .mdc-list-item__primary-text .mat-icon {
   color: #ffb74d !important;
}
.login-password {
   width: 100% !important;
   max-width: 533px !important;
   background: #2962D3;
   padding: 0px 0 51px 0;
   margin: 0 auto !important;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.login-password h3 {
   font-style: normal;
   font-weight: 400;
   font-size: 19px;
   line-height: 19px;
   text-align: center;
   color: #FFBA01;
}
.login-password .mat-mdc-card-outlined {
   background: none;
   border: 0;
   box-shadow: unset !important;
}
.login-password .mdc-line-ripple::before, .login-password .mdc-line-ripple::after {
   border: 0 !important;
}
.login-password .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
   padding-top: 0px !important; /*23px*/
   padding-bottom: 0px !important; /*4px*/
}
.login-password .login-window button.mdc-button {
   margin-top: 17px;
   height: 49px;
   background: #39B54A !important;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 30px;
   font-size: 18px !important;
   font-weight: 300 !important;
}
.login-password .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
   padding-top: 0px !important;
   padding-bottom: 0px !important;
   max-width: none;
}
app-verify .login-password .form-w380{
   max-width: 380px;
   width: 100%
}
app-verify .login-password .mdc-text-field__input{
   max-width: 310px;
}
app-verify .login-password button.mdc-button, 
.signup-window button.mdc-button,
app-reset-password  button.mdc-button{
   margin-top: 17px;
   height: 49px;
   background: #39B54A !important;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 30px;
   font-size: 18px !important;
   font-weight: 300 !important;
}
.login-password .mat-mdc-text-field-wrapper{
   position: absolute;
}
.login-password .omni-link{
   margin-top: 23px;
}
app-verify{
   position: relative;
}
app-verify * {
   letter-spacing: 0px !important;
}
app-verify .mat-mdc-raised-button {
   --mdc-protected-button-container-height: 38px;
   margin-top: 4px;
}
.omniscent-app app-verify .mat-mdc-card-title {
   margin-bottom: 15px !important;
}
app-verify .form-group {
   margin-bottom: 4px !important;
}
app-verify .mat-mdc-form-field-infix {
   min-height: 28px !important; /*42px*/
}
app-verify .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
   padding-top: 0px !important; /*23px*/
   padding-bottom: 0px !important; /*4px*/
   max-width: 310px;
}
app-verify .sign-form-area .sign-form-wrapper input {
   font-size: 18px !important;
   letter-spacing: 0;
}
app-verify .mat-mdc-form-field-hint-wrapper, app-verify .mat-mdc-form-field-error-wrapper {
   padding: 0 0px !important;
}
.login-password .mat-mdc-form-field-subscript-wrapper {
   top: 33px;
}
app-verify .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
   font-size: 18px !important;
   letter-spacing: 0;
}
.logo-outer{
   text-align: center;
   background: #f2f3f8;
   padding-bottom: 30px;
   padding-top: 40px;
   max-width: 533px;
   margin: 0 auto !important;
}
.logo-outer img{
   width: 320px;
}
app-verify .verify-area{
   padding-top: 40px;
}
.login-password-outer{
   max-width: 533px;
   width: 533px;
   margin: 0 auto;
   /*position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);*/
}
.login-password-outer .password-tip-container {
   color: #fff;
}
.omniscent-app .login-password-outer .mat-mdc-card-title, .signup-window .mat-mdc-card-title{
   color: #FFBA01 !important;
   text-align: center;
}
app-verify .form-group.set-pass {
   margin-bottom: 20px !important;
}
.signup-window .password-tip-container {
   color: #fff;
}
.login-window.signup-window .omni-link {
   margin-top: 0px;
}
.login-window.signup-window {
   width: 100% !important;
   max-width: 533px !important;
   background: #2962D3;
   padding: 0px 0 0px 0;
   margin: 0 auto !important;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   position: inherit;
    top: unset;
    left: unset;
    transform: unset;
    display: unset;
}
.signup-window .welcome-login,
app-reset-password .welcome-login {
   width: 100%;
}
.register-outer {
   max-width: 533px;
   width: 533px;
   margin: 0 auto;
}
.omniscent-app .register-outer .mat-mdc-card-title,
app-reset-password .reset-password .mat-mdc-card-title{
   margin-bottom: 15px !important;
}
.register-outer .field-bg,
app-reset-password .field-bg {
   margin-bottom: 20px !important;
   padding: 10px 20px 12px 20px !important;
}
.register-outer .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
   padding-top: 0px !important;
   padding-bottom: 0px !important;
}
.register-outer .sign-form-area .form-group .mat-icon,
app-reset-password .sign-form-area .form-group .mat-icon {
   color: #939191;
   width: 28px !important;
   margin-top: 2px;
}
.register-outer .mat-mdc-form-field-hint-wrapper, .register-outer .mat-mdc-form-field-error-wrapper,
app-reset-password .mat-mdc-form-field-error-wrapper{
   padding: 0 !important;
}
.register-outer .sign-form-area .sign-form-wrapper input,
app-reset-password .sign-form-area .sign-form-wrapper input {
   font-size: 18px !important;
}
.register-outer .sign-form-area .sign-form-wrapper,
app-reset-password .sign-form-area .sign-form-wrapper {
   width: 430px;
}
.register-outer mat-form-field, app-verify  mat-form-field,
.login-window .mat-mdc-form-field,
app-reset-password .mat-mdc-form-field{
   position: relative !important;
}
.register-outer .mat-mdc-form-field-subscript-wrapper, app-verify .mat-mdc-form-field-subscript-wrapper, 
.login-window .mat-mdc-form-field-subscript-wrapper{
   width: 100% !important; /*302px*/
}
.register-outer .mat-mdc-text-field-wrapper, 
.login-window .mat-mdc-text-field-wrapper,
app-reset-password .mat-mdc-text-field-wrapper {
   max-width: 330px;
}
app-verify .mat-mdc-text-field-wrapper{
   max-width: 360px;
}
.register-outer .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
app-verify .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.login-window .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
app-reset-password .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
.register-outer .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
app-verify .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
.login-window .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
app-reset-password .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before{
opacity: 0 !important;
}
app-verify .mat-mdc-icon-button.mat-mdc-button-base, .login-window .mat-mdc-icon-button.mat-mdc-button-base{
   margin-top: -10px;
   padding: 0 !important;
   width: 28px;
}
.register-outer .mat-mdc-icon-button.mat-mdc-button-base,
app-reset-password .mat-mdc-icon-button.mat-mdc-button-base{
   margin-top: -30px;
   padding: 0 !important;
   width: 28px;
}
.p10-4{
   padding: 0px 10px 0 4px;
}
.register-outer .mat-mdc-icon-button .mat-mdc-button-touch-target,
app-verify .mat-mdc-icon-button .mat-mdc-button-touch-target,
.login-window .mat-mdc-icon-button .mat-mdc-button-touch-target,
app-reset-password .mat-mdc-icon-button .mat-mdc-button-touch-target {
   height: 28px;
   width: 28px;
}
app-reset-password .login-password{
   padding: 0px 0 0px 0 !important;
}
app-rpaenhancement{
   width: 100%;
}
.rpa-left-block{
   border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
   padding-top: 10px !important;
}
.p0{
   padding: 0 !important;
}
.p0-15{
   padding: 0 15px;
}
.table.table.table-striped.custom-table-ui-block thead tr th {
   background-color: #ebebed;
   color: #212529;
}
table.table.table-striped.custom-table-ui-block tbody tr:nth-of-type(odd) {
   background-color: #fff;
}
table.table.table-striped.custom-table-ui-block tbody tr {
   background-color: #f9f9f9;
}
.table-bordered.table-striped.custom-table-ui-block th {
   border: 1px solid #d9d9d9;
}
.table-bordered.table-striped.custom-table-ui-block td{
   border: 1px solid #ebebed;
}
.color-black{
   color: #212529;
}
.color-blue {
   color: #2962d3;
}
.control-rentention-visiblity.custom-table-ui-block {
   margin-bottom: 20px;
}
.font-w600{
   font-weight: 600;
}
.rpa-left-block-outer .mdc-form-field{
   width: 150px;
}
.rpa-left-block-outer .rpaendownload{
   padding-top: 6px;
}
/*.sensor-name-customer-one {
   position: absolute;
   top: 9px;
   box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
   white-space: nowrap;
   background: #fff;
   padding: 7px 10px;
   color: #212529;
   margin-left: 10px;
   text-align: center;
}*/
.sensor-name-customer-one {
   position: absolute;
   top: 9px;
   box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
   background: #fff;
   padding: 7px 10px;
   color: #212529;
   margin-left: 10px;
   text-align: center;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   max-width: calc(100% - 380px);
   display: inline-block;
}
.sensor-name-customer-two {
   display: none;
}
.htmltooltip {
   position: relative;
   display: inline-block;
   cursor: pointer;
}

/* Tooltip text */
.htmltooltip .htmltooltiptext {
   visibility: hidden;
   width: max-content;
   background-color: #000;
   text-align: center;
   border-radius: 6px;
   padding: 10px;
   position: absolute;
   z-index: 1;
   top: 125%;
   left: 50%;
   margin-left: -60px; /* Center the tooltip */
   opacity: 0;
   transition: opacity 0.3s;
}

/* Show the tooltip text when hovering over the tooltip container */
.htmltooltip:hover .htmltooltiptext {
   visibility: visible;
   opacity: 1;
}
.disBlock{
   display: inline-block;
}
.w-full{
   width: 100%;
}
.modes-for-model li{
   list-style: none;
}
.heading-style1{
   color: #212529;
   font-weight: 600;
}

@media screen and (max-width: 420px) {
.register-outer .mat-mdc-form-field-error, .app-verify .mat-mdc-form-field-error, .login-window .mat-mdc-form-field-error, .login-password .mat-mdc-form-field-error {
      line-height: 14px;
  }
.register-outer .field-bg, .app-verify .field-bg, .login-password .field-bg {
   margin-bottom: 36px !important;
}
app-verify .form-group.set-pass {
   margin-bottom: 36px !important;
} 
} 
@media screen and (max-width: 576px) {
.register-outer .sign-form-area .sign-form-wrapper, .reset-password .sign-form-area .sign-form-wrapper{
   width: 100%;
   max-width: 430px;
}
.register-outer{
   max-width: 533px;
   width: 100%;
   margin: 0 auto;
}
.logo-outer img {
   max-width: 320px;
   width: 100%;
   padding: 0 10px;
}
.login-password-outer, .reset-password {
   max-width: 533px;
   width: 100%;
}
}

@media only screen and (max-width: 1441px) {
   .modal-step-config .mat-mdc-dialog-content{
      min-width: 1050px;
      min-height: 400px;
   }
}
@media only screen and (min-width: 813px) {
app-rpaenhancement .col.rpa-right-block{
   width: calc(100% - 230px) !important;
}
.rpa-right-block .mat-mdc-tab-header{
   height: 57px;
}
.rpa-right-block .mat-mdc-tab-header .mat-mdc-tab-labels .mdc-tab{
   height: 56px;
}
}
@media screen and (max-width: 812px) {
.sensor-name-customer-one {
   display: none;
}
.sensor-name-customer-two {
   box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
   white-space: nowrap;
   background: #fff;
   padding: 7px 10px;
   color: #212529;
   text-wrap: wrap;
   text-align: center;
   font-size: 14px;
   width: 100%;
   display: block;
   margin-top: 10px;
}      
.rpa-right-block {
   padding: 0 15px !important;
}
.rpa-left-block{
   padding: 0 15px !important;
}
.rpa-left-block-outer{
   padding: 0 15px !important;
   max-width: 100% !important;
   margin-bottom: 15px;
}
}
@media screen and (max-width: 980px) {   
.login-window .sign-form-area{
   height: inherit;
   padding: 20px 0;
} 
app-reset-password .sign-form-area {
   height: inherit;
   padding: 20px 0 40px 0; 
} 
.login-welcome-msg {
   width: 100%;
   padding: 40px 20px;
}
.welcome-login {
   width: 100%;
   padding: 0px 0 0px 0;
}
.login-welcome-msg .logo {
   width: 70%;
   max-width: 300px;
}
.login-window {
   max-width: 975px;
   width: 96%;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   margin: 0 auto !important;
   position: inherit;
   top: unset;
   left: unset;
   transform: unset;
   display: unset;
   background: #fff;
}
}
@media only screen and (min-width: 576px) {
.history-configuration .col-sm-3 {
      max-width: 45%;
  }
}
.mdc-switch--selected .mdc-switch__icon--on, .mdc-switch--unselected .mdc-switch__icon--off {
   opacity: 0 !important;
}
.mdc-switch:enabled .mdc-switch__track::before {
   background: rgba(0, 0, 0, 0.38) !important;
}
.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
   background:#fafafa !important;
   box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.fast-mode-sensor .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
   font-size: 16px !important;
}
.mat-mdc-card-title, .mat-mdc-card-subtitle {
   margin-bottom: 16px !important;
}
.mat-mdc-card>:last-child:not(.mat-mdc-card-footer), .mat-mdc-card-content>:last-child:not(.mat-mdc-card-footer) {
   margin-bottom: 0 !important;
}
mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
   margin-left: 0px;
   padding: 0;
   width: 24px;
}
.mdc-floating-label{
   width: 100%;
}
.mdc-button{
   white-space: nowrap;
}
app-customer-topbar .customer-topbar-wrap .text-center.title.pb-4.pt-5 {
   padding-top: 48px !important;
   padding-bottom: 16px !important;
}
app-customer-topbar .mat-mdc-radio-button .mdc-radio{
   padding: 0px !important;
}
app-customer-topbar .mat-radio-label-content{
   padding-left: 6px;
}
app-customer-topbar label.mdc-label {
   margin-left: 5px !important;
    margin-top: 8px !important;
    margin-bottom: 7px !important;
    font-size: 1rem !important;
}
app-sensor-type-list .mdc-list-item__primary-text {
   color: #575962 !important;
}
app-sensor-type-list .mdc-list-item:hover{
   cursor: pointer;
}
app-sensor-type-list .mdc-list-item:hover .mdc-list-item__primary-text {
   color: #575962 !important;
   letter-spacing: 0px !important;
}
app-sensor-type-list .mdc-list-item.list-active{
   background-color: #82b1ff;
}
app-sensor-type-detail .mdc-list-item__primary-text {
   color: #575962 !important;
   letter-spacing: 0px !important;
}
app-sensor-type-detail .mdc-list-item:hover{
   cursor: pointer;
}
app-sensor-type-detail .mdc-list-item:hover .mdc-list-item__primary-text {
   color: #575962 !important;
}
app-sensor-type-detail .mdc-list-item.list-active{
   background-color: #82b1ff;
}
app-sensor-type-detail .delete-item .mat-icon {
   color: #f44336;
}
.delete-item .mat-icon {
   color: red;
}
.mdc-dialog .mdc-dialog__content {
   padding: 20px 0px 0px 0px !important;
}
app-sensor-list .omni-table-header {
   height: 45px !important;
   line-height: 15px;
}
.mdc-dialog__title{
   padding: 0 0px 9px !important;
}
.edit-item .mat-icon{
color: #39b54a;
}
.mat-mdc-icon-button svg {
   width: 16px !important;
}
.mat-mdc-icon-button.mat-mdc-button-base{
   margin-left: 12px;
}
app-top-bar .mdc-button{
   direction: rtl;
}
.mat-mdc-paginator-container{
   color: #575962;
}
app-top-bar .mat-mdc-button>.mat-icon {
   font-size: 24px !important;
   color: #2962d3;
}
.mat-mdc-text-field-wrapper {
   height: 42px;
}
.mdc-notched-outline__trailing {
   border-right: 0 !important;
    border-top: 0 !important;
    border-radius: 0 !important;
}
.mdc-notched-outline__leading {
   border-left: 0 !important;
   border-top: 0 !important;
   border-radius: 0 !important;
}
.mat-mdc-paginator-page-size-select {
   width: 56px !important;
}
app-admin .mat-mdc-paginator-page-size-select {
   width: 56px ;
}
app-admin .mat-mdc-row{
   height: 46px !important;
}
app-admin mat-sidenav .mat-icon {
   color: #e1ffff !important;
}
app-admin mat-sidenav .mdc-list-item__primary-text {
   color: #e1ffff !important;
   display: flex;
   letter-spacing: 0px;
}
app-admin .mdc-button__label .mat-icon{
   color: #2962d3;
}
app-admin .mat-mdc-icon-button.mat-warn .mat-icon {
   color: #f44336;
}
.mdc-list-item:hover .mdc-list-item__primary-text {
   color: #e1ffff !important;
}
mat-option.mdc-list-item:hover .mdc-list-item__primary-text {
   color: #000 !important;
}
mat-option.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
   color: #2962d3 !important;
}
mat-dialog-container.mdc-dialog app-user-modal .mdc-dialog__content {
   padding: 0 !important;
}
mat-dialog-container.mdc-dialog app-user-modal .mdc-dialog__title {
   padding: 0 0px 9px !important;
}
mat-dialog-container.mdc-dialog app-invite-modal .mdc-dialog__title{
   padding: 0 0px 9px !important;
}
mat-dialog-container.mdc-dialog app-invite-modal .mdc-dialog__content {
   padding: 0 !important;
}
.child-nav .mdc-list-item__content .mdc-list-item__primary-text {
   padding: 0 16px 0 20px !important;
}
app-login .mat-icon{
   color: #fff;
   font-size: 24px !important;
}
app-customer-topbar .mat-icon{
   color: #575962;
}
.mat-mdc-radio-button .mdc-form-field {
   color: #575962;
}
debug-page-new .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
   font-size: 13px !important;
}
debug-page-new .mat-mdc-form-field-flex{
   background-color: rgba(0, 0, 0, 0.04);
   padding: 0.75em 0.75em 0 0.75em !important;
}
debug-page-new .mat-form-field-appearance-fill.mat-mdc-form-field-infix{
   padding: 0.25em 0 0.75em 0 !important
}
raw-data .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
   color: #000 !important;
}
.live-page .mdc-button .mdc-button__label{
   letter-spacing: normal;
   font-size: 16px;
}
.mat-mdc-card {
   transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
   padding: 16px;
   border-radius: 4px;
}
.mat-mdc-card-content {
   padding: 0 0px !important;
}
.material-icons {
   font-family: 'Material Icons' !important;
}
* {
   font-family: 'Oswald', sans-serif !important;
}
.omniscent-app .mat-mdc-card-title {
   font-size: 24px;
   font-weight: 500;
   margin-bottom: 8px !important;
}
.omniscent-app .mdc-checkbox{
   padding: 0;
}
.omniscent-app .mat-mdc-checkbox-touch-target{
   width: 18px;
   height: 18px;
}
.omniscent-app .mdc-checkbox .mdc-checkbox__background{
   margin-right: 8px;
   top: 0;
   left: 0;
}
.omniscent-app .mdc-checkbox .mdc-checkbox__native-control{
   width: 18px;
   height: 18px;
}
.omniscent-app label.mdc-label {
   margin-left: 6px;
   margin-top: 7px;
}
.omniscent-app .mat-mdc-card-content {
   font-size: 14px;
}
.mdc-text-field input#mat-input-5::before {
   content: "Read this: ";
 }
 .mdc-text-field--filled:not(.mdc-text-field--disabled){
   background: none !important;
 }
 .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
   opacity: 0 !important;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
   font-size: 14px;
}
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay{
   opacity: 0 !important;
}
.dis-flex{
   display: flex;
}
.detail-viewer-title {
   display: flex;
   align-items: center!important;
   margin-right: 20px;
   color: #575962;
}
.detail-viewer-description {
   display: inline-block;
   color: #575962;
}
.detail-viewer-description p{
   margin-bottom: 0;
}
.detail-viewer-container {
   box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
   0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
   0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}  
.detail-viewer-description h4 {
   font-size: 16px;
   margin-bottom: 0;
}
.detail-viewer-description p {
   font-size: 14px;
}  
button.btn.mdc-button.btn-primary {
   color: #fff;
}
.mat-mdc-card-outlined {
   box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.detection-history .omni-table-header {
   height: 26px !important;
}  
.omni-table-header{
   height: 26px !important;
}  
/*.container-fluid{
   padding-left: 0 !important;
   padding-right: 0 !important;
}*/
.mat-mdc-dialog-surface.mdc-dialog__surface {
   padding: 24px;
}
.mat-mdc-header-cell{
   font-size: 12px;
   color: rgba(0, 0, 0, 0.54);
}
debug-page-new mat-card mat-card-content.mat-mdc-card-content{
   padding: 16px 0 !important;
}
.mdc-text-field{
   padding: 0 0 !important;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
   padding-top: 24px !important;
   padding-bottom: 8px !important;
}
.sensor-name.mat-focus-indicator.btn.mat-raised-button.mat-button-base.btn-default {
   box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
   white-space: nowrap;
   background: #fff;
   padding-top: 4px;
}
.sensor-name.mat-focus-indicator.btn.mat-raised-button.mat-button-base.btn-default:active{
   box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
   flex-grow: 0 !important;
   min-width: 160px;
}
.mat-mdc-tab-header {
   border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label{
   letter-spacing: 0px;
}
/****** End Modified Styles 18-Dec-20223*****/
$progress-bar-scrollbar-height: 5px;
$progress-bar-scrollbar-padding: 15px;
$content-color: #575962;
$title-color: #3f4047;
$grey-color: #6f727d;

@mixin progress-bar-scroll-style() {
   &::-webkit-scrollbar {
      width: 100%;
      height: (($progress-bar-scrollbar-padding * 2) + $progress-bar-scrollbar-height);
   }

   &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border: 16px solid rgba(255, 255, 255, 0);
      border-left: 0;
      border-right: 0;
      background-clip: padding-box;
      background-color: #ccc;
   }

   &::-webkit-scrollbar-thumb {
      border: 16px solid rgba(255, 255, 255, 0);
      border-left: 0;
      border-right: 0;
      background-clip: padding-box;
      background-color: mat.get-color-from-palette($app-primary, A400);
      &:hover {
         border: $progress-bar-scrollbar-padding solid rgba(255, 255, 255, 0);
         border-left: 0;
         border-right: 0;
      }
   }
}

* {
   font-family: 'Oswald', sans-serif;
}

html {
   display: flex;
   height: 100%;
}

body {
   width: 100%;
}

.omniscent-app {
   background-color: #f2f3f8;
   color: $content-color;
}

.main-app {
   background-color: #f2f3f8;
}

button {
   outline: none !important;
}

.margin-auto {
   margin: auto !important;
   display: block !important;
}

.no-border {
   border: 0 !important;
}

.no-padding {
   padding: 0 !important;
}

.no-margin {
   margin: 0 !important;
}

.no-underline {
   text-decoration: none !important;
}

.text-center {
   text-align: center;
}

.relative {
   position: relative;
}

.capitalize {
   text-transform: capitalize;
}

.border-none {
   border: none !important;
   border-radius: 0 !important;
}

.border-right {
   border-right: 1px solid $content-color;
}

.text-wrap {
   word-wrap: break-word;
}

.float-left {
   float: left !important;
}

.float-right {
   float: right !important;
}

.flex-row-center {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-flow: row;
}

.tooltipRed {
   background: red;
}

.mr-5px {
   margin-right: 5px;
}

.edit-view-wrap {
   display: flex;
   flex-flow: row;
   justify-content: space-between;
   align-items: center;

   .edit-area {
      min-width: 40%;
      text-align: left;
   }
}

.load-area {
   margin: 20px 0;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.topbar-gradient-area {
   background: mat.get-color-from-palette($app-primary, 200);
}

.light-green-bg {
   background: mat.get-color-from-palette($app-green, A100) !important;
}

.password-tip-container {
   color: darkgrey;
}

/* Title styles */
.big-title {
   font-size: 32px;
   color: $title-color;
}

.title {
   padding: 20px 0;
   margin: 0;
   font-size: 24px;
   color: $title-color;
}

.sub-title {
   font-size: 20px;
   color: $title-color;
   padding: 0;
   margin: 10px 10px 20px;
}

h1,
h2,
h3,
h4,
h5 {
   background: transparent;
   height: auto;
}

.content {
   color: $content-color;
}

.pointer {
   cursor: pointer;

   i {
      font-size: 14px;
   }
}

.relative {
   position: relative;
}

.status-title {
   color: green;
   font-size: 15px;
   background: #d3d3d3;
   padding: 5px;
   border-radius: 5px;
   margin-left: 5px;
}

.blue-text {
   color: mat.get-color-from-palette($app-primary) !important;
}

.green-text {
   color: mat.get-color-from-palette($app-green) !important;
}

.accent-text {
   color: mat.get-color-from-palette($app-accent) !important;
}

.grey-text {
   color: $grey-color !important;
}

.green-background {
   background-color: mat.get-color-from-palette($app-green);
}

.red-color {
   color: #ff7961;
}

.white-bg {
   background-color: #fff;
}

.md-select-wrapper {
   margin-top: 22px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-select-trigger {
   height: auto !important;
}

.overflow-x,
.table-responsive {
   overflow-x: auto;

   &::-webkit-scrollbar {
      width: 100%;
      height: 10px;
   }

   &::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($app-primary);
      border-radius: 10px;
      background-image: -webkit-linear-gradient(
         rgba(255, 255, 255, 0.5) 25%,
         transparent 25%,
         transparent 50%,
         rgba(255, 255, 255, 0.5) 50%,
         rgba(255, 255, 255, 0.5) 75%,
         transparent 75%,
         transparent
      );
   }
}

.overflow-y,
.full-height {
   overflow-y: auto;
   &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
   }

   &::-webkit-scrollbar-thumb {
      background-color: mat.get-color-from-palette($app-primary);
      border-radius: 10px;
      background-image: -webkit-linear-gradient(
         0deg,
         rgba(255, 255, 255, 0.5) 25%,
         transparent 25%,
         transparent 50%,
         rgba(255, 255, 255, 0.5) 50%,
         rgba(255, 255, 255, 0.5) 75%,
         transparent 75%,
         transparent
      );
   }
}

.static-wrapper {
   min-width: 1070px;
}

.datatable-body-cell-label {
   height: 100%;
   display: flex;
   align-items: center;
}

.small-spinner {
   width: 20px !important;
   height: 20px !important;

   svg {
      width: 20px !important;
      height: 20px !important;
   }
}

input[type='text'] {
   border: 0;
}

.table-control-area {
   margin: 20px 0 0;
}

.table-control-panel {
   display: flex;
   flex-flow: row;
   width: 75%;
   margin: 0 auto 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
.table-control-area .mat-mdc-input-wrapper {
   margin: 0;
}

.no {
   /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
   .mat-mdc-input-wrapper {
      padding: 0 !important;
      margin: 0 !important;
   }
}

.pages {
   &.active {
      background: transparent;
      border: 0;
   }
   a {
      border: 0;
   }
}

.datatable-pager {
   .pager {
      li {
         & > a {
            border: 0;
            background: transparent;
         }
      }
   }
}

.cards-wrapper {
   margin: 0 10px;

   .mat-mdc-card {
      margin: 40px 0;
   }

   .mat-card {
      margin: 40px 0;
   }
}

.flex-center {
   display: flex;
   align-items: center;
   justify-content: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix {
   width: auto !important;
}
.mat-mdc-form-field-infix {
   width: auto !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-label-wrapper.mat-input-placeholder-wrapper {
   font-size: 16px;
}

.mat-mdc-form-field-label-wrapper.mat-mdc-input-placeholder-wrapper {
   font-size: 16px;
}

.mat-form-field-label-wrapper.mat-mdc-input-placeholder-wrapper {
   font-size: 16px;
}

.mat-mdc-form-field-label-wrapper.mat-input-placeholder-wrapper {
   font-size: 16px;
}

.filter-container {
   width: 50%;
}

/* Button styles */
.btn {
   border-radius: 0 !important;
   outline: none !important;
   letter-spacing: normal;
}

.btn-icon {
   font-size: 1.5em !important;
}

.add-icon {
   .mat-icon {
      font-size: 28px !important;
   }
}

.btn.btn-primary {
   background-color: mat.get-color-from-palette($app-primary) !important;
   color: #fff;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-primary) !important;
      color: #fff;
   }
}

.btn.btn-success {
   background-color: mat.get-color-from-palette($app-green) !important;
   color: #fff;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-green) !important;
      color: #fff;
   }
}

.btn.btn-info {
   background-color: mat.get-color-from-palette($app-cyan) !important;
   color: #fff;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-cyan) !important;
      color: #fff;
   }
}

.btn.btn-light-green {
   background-color: mat.get-color-from-palette($app-green, A400) !important;
   color: #fff;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-green, A400) !important;
      color: #fff;
   }
}

.btn.btn-warning {
   background-color: mat.get-color-from-palette($app-orange) !important;
   color: #fff;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-orange) !important;
      color: #fff;
   }
}

.btn.btn-danger {
   color: #fff;
   background-color: mat.get-color-from-palette($app-warn, 400) !important;
   border-color: #d43f3a;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-warn, 400) !important;
      color: #fff;
   }
}

.btn.btn-secondary {
   background-color: mat.get-color-from-palette($app-grey, 500) !important;
   color: #fff;

   &:active,
   &:target,
   &:focus {
      background-color: mat.get-color-from-palette($app-grey, 500) !important;
      color: #fff;
   }
}

button {
   &.btn-icon {
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-wrapper {
         padding: 0;
         line-height: initial;
         display: flex;
         align-content: center;
         justify-content: center;
      }

      .mat-mdc-button-wrapper {
         padding: 0;
         line-height: initial;
         display: flex;
         align-content: center;
         justify-content: center;
      }
   }

   &.no-padding {
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-wrapper {
         padding: 0;
      }
      .mat-mdc-button-wrapper {
         padding: 0;
      }
   }

   &:disabled {
      cursor: not-allowed !important;
      pointer-events: auto !important;
   }
}

.multi-action-btn-wrap {
   display: flex;
   flex-flow: row;
   justify-content: space-around;
}

/* Google map styles */
.sebm-google-map-container {
   height: 100%;
}

.sebm-google-map-container-inner {
   height: 100%;
}

/* Icon styles */
.mat-icon {
   height: auto !important;
   width: auto !important;
}

.calibration-link {
   .mat-icon {
      width: 36px !important;
      height: 36px !important;
   }
}

.cdk-overlay-container {
   z-index: 2000 !important;
}

.flex-wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
}

.data-title-wrapper {
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.full-height {
   height: 100%;
   overflow: auto;
}

.full-width {
   width: 100% !important;
}
.data-actions {
   width: 20%;
}

.click {
   cursor: pointer;
}

.action-button-area {
   margin-top: 1em;
}

.page-link {
   margin-top: 1em;
   margin-bottom: 1em;

   a {
      text-decoration: underline;
      cursor: pointer;
   }
}

.clear {
   clear: both;
}

.capitalize {
   text-transform: capitalize;
}

.inline {
   display: inline-block;
}

.select-form-group {
   padding-top: 1em;
}

.action-wrapper {
   display: flex;
   flex-flow: row;
   justify-content: center;
   align-items: center;

   .btn {
      width: 120px;
   }

   button {
      margin-right: 10px;
   }

   .inline {
      button.btn {
         margin-bottom: 5px;
      }
   }

   .btn-action {
      font-size: 15px;
      margin: 0 0 1em 1em;
      display: block;
   }
}

.description-section {
   button {
      text-align: center;
      word-break: break-all;
      display: block;
      white-space: initial;
   }
}

.list-active {
   cursor: pointer;
   color: $content-color !important;
   background-color: mat.get-color-from-palette($app-primary, A100);
   border: 1px solid mat.get-color-from-palette($app-primary, A100);
}

.inline-radio-area {
   display: inline-flex;
   flex-direction: column;
}

.ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

/* Omniscent styles */
.omniscent-view {
   display: flex;
   flex-flow: column;
   height: 100%;

   .omniscent-title {
      font-size: 26px;
      margin: 2em 0 1em;
      color: $title-color;
   }

   .omniscent-wrap {
      width: 100%;
      flex-grow: 1;
   }
}

.omni-link {
   cursor: pointer;

   &:hover {
      text-decoration: underline !important;
   }
}

.omni-table-header {
   text-align: center;
   height: 4em;
   display: flex;
   justify-content: center;
   align-items: center;
}

.omni-table {
   .mat-mdc-row {
      align-items: stretch;
      border-bottom-color: rgba(0, 0, 0, 0.12);
      height: auto;

      .mat-mdc-cell {
         border-right: 1px solid rgba(0, 0, 0, 0.12);
         border-color: rgba(0, 0, 0, 0.12);
         color: $content-color;
         padding: 0;

         &:last-child {
            border-right: 0;
         }
      }
   }
}

.omni-col-wrap {
   display: flex;
   flex-flow: column;
   text-align: center;
   justify-content: center;
   width: 100%;
   height: 100%;

   .omni-col-item {
      display: flex;
      flex-wrap: wrap;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0.5rem;
      height: 48px;
      min-height: 48px;

      &:first-child {
         border: 0;
      }
   }
}

.omni-list-group {
   .omni-list-item {
      cursor: pointer;

      &:hover {
         background-color: mat.get-color-from-palette($app-primary, 100);
      }
   }
}

.omniscent-catalog-view {
   display: flex;
   flex-flow: row;
   width: 100%;
   height: 100%;

   @media screen and (max-width: 767px) {
      flex-flow: column;
   }

   .omniscent-name-list {
      width: 25%;
      border-right: 1px solid #000;

      @media screen and (max-width: 767px) {
         width: 100%;
         border: 0;
         margin-bottom: 1em;
      }
   }

   .omniscent-detail-view {
      width: 75%;
      display: flex;
      flex-flow: column;
      height: 100%;

      @media screen and (max-width: 767px) {
         width: 100%;
      }
   }
}

.data-list {
   padding: 0 !important;

   .data-list-container {
      margin: 3px !important;
      background-color: #f2f3f8;
      height: calc(100% - 6px);
      overflow: auto;

      .card {
         background-color: #fff;
      }
   }

   .title {
      padding-left: 3px;
   }
}

.underline {
   text-decoration: underline;
}

/* Require label styles */
.form-group.required {
   .control-label:after {
      content: '*';
      color: red;
   }
}

/* Material custom styles */
.omniscent-app {
   .mat-mdc-card-title {
      color: $title-color;
   }

   .mat-mdc-list {
      .mat-mdc-list-item {
         color: $content-color;
      }
   }
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-label {
   white-space: normal;
}

.mat-mdc-checkbox-label {
   white-space: normal;
}

.mat-mdc-dialog-container {
   .mat-mdc-dialog-title {
      font-family: 'Oswald', sans-serif;
   }
}

.gas-library.mat-mdc-select-panel {
   max-width: unset;
}

.mat-mdc-list-item {
   i {
      padding-right: 0px;
   }

   border: 0;
}

.child-nav {
   position: relative;

   .mat-mdc-list-item {
      /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
      .mat-list-item-content {
         padding: 0 16px 0 36px !important;
      }

      .mat-mdc-list-item-content {
         padding: 0 16px 0 36px !important;
      }

      &::before {
         content: '';
         display: block;
         position: absolute;
         width: 8px;
         left: 28px;
         top: 24px;
         border-top: 1px solid #e1ffff;
         z-index: 1;
      }
   }

   &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      left: 28px;
      top: 0;
      bottom: 0;
      border-left: 1px solid #e1ffff;
   }
}

.admin-sidenav {
   background-color: #2e353d;
   /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
   .mat-list-item-content {
      color: #e1ffff !important;
      word-break: break-all;
   }

   .mat-mdc-list-item-content {
      color: #e1ffff !important;
      word-break: break-all;
   }

   .child-nav {
      .active {
         /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
         .mat-list-item-content {
            color: mat.get-color-from-palette($app-orange, 300) !important;
         }

         .mat-mdc-list-item-content {
            color: mat.get-color-from-palette($app-orange, 300) !important;
         }
      }

      .mat-mdc-list-item {
         i {
            margin-left: 3px;
         }
      }
   }

   .active {
      background: transparent;
   }
}

/* Fieldset styles */
fieldset {
   border: 1px groove #ddd !important;
   padding: 0 1.4em 1.4em 1.4em !important;
   margin: 0 0 1.5em 0 !important;
   -webkit-box-shadow: 0px 0px 0px 0px #000;
   box-shadow: 0px 0px 0px 0px #000;

   legend {
      font-size: 1.2em !important;
      font-weight: bold !important;
      text-align: left !important;
      width: auto;
      padding: 0 10px;
      border-bottom: none;
   }
}

/* Table styles */
table {
   .file-name {
      max-width: 200px;
      word-break: break-all;
   }
}

.fixed-table {
   table-layout: fixed;
}

.table {
   .table {
      background-color: transparent !important;
   }

   td {
      position: relative;
   }
}

table.table {
   > tbody {
      > tr {
         > td {
            vertical-align: middle;
            text-align: center;
         }
      }
   }
   > thead {
      > tr {
         > th {
            vertical-align: middle;
            text-align: center;
         }
      }
   }
   &.table-striped {
      thead {
         tr {
            th {
               background-color: mat.get-color-from-palette($app-primary, 700);
               color: #fff;
            }
         }
      }

      tbody {
         tr {
            background-color: mat.get-color-from-palette($app-primary, 50);

            &:nth-of-type(odd) {
               background-color: mat.get-color-from-palette($app-primary, 100);
            }
         }
      }
   }
}

.table-action-wrapper {
   display: flex;
   flex-flow: row;
   justify-content: space-around;
   align-items: center;
   width: 100%;
}

table.multi-cell {
   margin: 0;
   width: 100%;
   display: block;

   tbody,
   tr,
   td {
      width: 100%;
      display: block;
   }

   tr:first-child {
      td {
         border-top: 0;
      }
   }

   td {
      border-top: 1px solid #ddd;
      height: 4em;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: auto;
   }
}

form {
   .form-control {
      border-radius: 5px;
      background-color: transparent;
      box-shadow: none;
      font-size: 14px;
   }
}

.form-control[type='file'] {
   height: auto;
}

.button-area {
   .btn {
      width: 140px;
   }
}

.delete-item {
   color: mat.get-color-from-palette($app-warn);
}

.edit-item {
   color: mat.get-color-from-palette($app-green);
}

/* List group styles */
.list-item {
   a {
      color: $content-color;
   }

   a.list-group-item:hover {
      color: $content-color !important;
   }

   .delete-item {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .counter-wrapper.off {
      border-color: mat.get-color-from-palette($app-warn) !important;
   }
}

.list-group {
   .list-group-item {
      background-color: transparent;
   }
}

/* Sign styles */
.tooltip-container {
   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
   .mat-form-field-subscript-wrapper {
      .tooltip-inner {
         background-color: mat.get-color-from-palette($app-green, 500);
      }
   }

   .mat-mdc-form-field-subscript-wrapper {
      .tooltip-inner {
         background-color: mat.get-color-from-palette($app-green, 500);
      }
   }
}
.sign-form-area {
   height: 100%;

   .fade:not(.show) {
      opacity: 1;
   }

   .logo {
      height: 50px;
      max-width: 200px;
      margin-bottom: 1em;
   }

   .sign-form-wrapper {
      width: 380px;

      @media only screen and (max-width: 576px) {
         width: 300px;
      }

      input {
         font-size: 18px;
      }

      .title {
         display: flex;
         flex-flow: column;
         justify-content: center;
         align-items: center;

         img {
            width: auto;
            height: 40px;
            margin: 0;
         }

         h2 {
            color: mat.get-color-from-palette($app-primary);
         }
      }
   }

   .form-group {
      .mat-icon {
         color: mat.get-color-from-palette($app-grey, A700);
      }
   }
}

@-webkit-keyframes glowing {
   0% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
   50% {
      background-color: mat.get-color-from-palette($app-green, 500);
   }
   100% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
}

@-moz-keyframes glowing {
   0% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
   50% {
      background-color: mat.get-color-from-palette($app-green, 500);
   }
   100% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
}

@-o-keyframes glowing {
   0% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
   50% {
      background-color: mat.get-color-from-palette($app-green, 500);
   }
   100% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
}

@keyframes glowing {
   0% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
   50% {
      background-color: mat.get-color-from-palette($app-green, 500);
   }
   100% {
      background-color: mat.get-color-from-palette($app-green, A400);
   }
}

/* Sensor detail page styles */
.sensor-detail-wrapper,
.sensor-run {
   .label-area {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
   }

   .circle-item {
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      border-radius: 50%;
      border: 1px solid;
      z-index: 10;
      background-color: white;
   }

   .bar-item {
      width: 100%;
      height: 10px;
      border: 1px solid $content-color;
      margin-left: -3px;
      margin-right: -3px;
      z-index: 1;
   }

   .belt-frame {
      height: 10px;
      position: absolute;
      left: 18px;
      top: 5px;
      z-index: 1;
      background-image: -webkit-linear-gradient(
         -45deg,
         rgba(255, 255, 255, 0.2) 25%,
         transparent 25%,
         transparent 50%,
         rgba(255, 255, 255, 0.2) 50%,
         rgba(255, 255, 255, 0.2) 75%,
         transparent 75%,
         transparent
      );
      box-shadow: inset 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
   }

   .step-progress-area {
      margin-top: 20px;
      overflow: auto;
      @include progress-bar-scroll-style();

      .empty-label {
         width: 20px;
      }

      .label-item,
      .progress-item {
         min-width: 100px;
      }
   }

   .run-progress-area {
      overflow: auto;
      @include progress-bar-scroll-style();

      .empty-label {
         width: 20px;
      }

      .label-item,
      .progress-item {
         min-width: 50px;
      }
   }

   .progress-bar-area {
      position: relative;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      .progress-item.progress {
         .label-area {
            margin-top: 10px;
         }
      }

      .progress-item {
         position: relative;
         flex: 1;
         background-color: transparent;
         border: 0;
         margin: 0;
         padding: 0;
         box-shadow: none;

         &.active {
            .belt-frame {
               background-color: mat.get-color-from-palette($app-primary, A400);
               width: 100% !important;
            }

            .circle-item {
               background-color: mat.get-color-from-palette($app-primary);
               border: 1px solid #fff;
            }
         }

         &.progress {
            display: block;
            height: 100%;
            overflow: unset;

            .belt-frame {
               -webkit-animation: glowing 1500ms infinite;
               -moz-animation: glowing 1500ms infinite;
               -o-animation: glowing 1500ms infinite;
               animation: glowing 1500ms infinite;
            }

            .circle-item {
               -webkit-animation: glowing 1500ms infinite;
               -moz-animation: glowing 1500ms infinite;
               -o-animation: glowing 1500ms infinite;
               animation: glowing 1500ms infinite;
               border: 1px solid #fff;
            }
         }

         &.error {
            .belt-frame {
               background-color: mat.get-color-from-palette($app-warn, A400);
               width: 100% !important;
            }

            .circle-item {
               background-color: mat.get-color-from-palette($app-warn);
               border: 1px solid #fff;
            }
         }

         .main-frame {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
         }
      }

      .progress-item.end-progress-item {
         min-width: 20px;
         max-width: 20px;

         .main-frame {
            .bar-item {
               display: none;
            }
         }

         .belt-frame {
            display: none;
         }
      }

      .progress-item.progress-done-item {
         .main-frame {
            .circle-item {
               background-color: mat.get-color-from-palette($app-primary);
               border: 1px solid #fff;
            }
         }
      }
   }

   .sensor-control-container {
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-wrapper {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         width: 100%;
      }

      .mat-mdc-button-wrapper {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         width: 100%;
      }

      .multi-line {
         line-height: 22px;
      }
   }
}

.modal-title {
   font-size: 22px;
}

/* Custom modal styles */
.config-modal-area {
   .card {
      display: flex;
      flex-direction: column;

      .card-header {
         border: 0;
         height: auto;

         ul.nav {
            li.nav-item {
               border-left: 0;
               border-right: 0;
               border-top: 0;
               background-color: transparent;

               a {
                  color: #292b2c;
                  font-size: 15px;
               }
            }
         }
      }

      .card-footer {
         height: auto;
      }
   }

   .select-mode-area {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
   }

   .config-form {
      table {
         tbody {
            .mat-mdc-form-field {
               padding: 0;
               /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
               /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
               .mat-form-field-wrapper {
                  margin-bottom: -1.25em;
                  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                  .mat-form-field-infix {
                     padding: 0;
                     border: 0;
                  }
               }

               .mat-mdc-form-field-wrapper {
                  margin-bottom: -1.25em;
                  .mat-mdc-form-field-infix {
                     padding: 0;
                     border: 0;
                  }
               }

               .mat-form-field-wrapper {
                  margin-bottom: -1.25em;
                  .mat-mdc-form-field-infix {
                     padding: 0;
                     border: 0;
                  }
               }

               .mat-mdc-form-field-wrapper {
                  margin-bottom: -1.25em;
                  .mat-form-field-infix {
                     padding: 0;
                     border: 0;
                  }
               }
            }
         }
      }
   }
}

.full-width-dialog .mat-mdc-dialog-container {
   max-width: 100vw !important;
   width: 90vw;
}

.strict-dialog .mat-mdc-dialog-container {
   max-width: 100vw !important;
   width: 800px;
}

/* Spinner styles */
.spinner-wrapper {
   .loader {
      .wrapper {
         div {
            background: mat.get-color-from-palette($app-primary, A400);
         }
      }
   }
}

.limit-sensors span {
   font-size: 16px;
}
